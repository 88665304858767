/*
Usage Example:

<script lang="ts" setup>
import useFocus from '@/components/utils/Focus'

const emit = defineEmits<{
  (event: 'focus'): void
  (event: 'blur'): void
}>()
const props = withDefaults(defineProps<{}>(), {})

const focus = useFocus(emit, props)
</script>

<template>
  <!-- classes as array -->
  <div :class="['tw-component', focus.class]" v-bind="˜{ ...focus.on }">
    <!-- component content -->
  </div>

  <!-- classes as object -->
  <div class="tw-component" :class="{ ...focus.classes }" v-bind="˜{ ...focus.on }">
    <!-- component content -->
  </div>
</template>
*/
export interface FocusEmits {
  (event: 'focus'): void
  (event: 'blur'): void
}

export interface FocusProps { }

export const IsFocusedClass = 'is--focused'

export default function useFocus(emit: FocusEmits, props: FocusProps) {
  const internalState = ref(false)
  const state = computed({
    get() {
      return internalState.value
    },
    set(v: boolean) {
      internalState.value = !!v
      if (state.value) emit('focus')
      else emit('blur')
    },
  })
  return {
    emit,
    props,
    state,
    class: computed(() => (state.value ? IsFocusedClass : false)),
    classes: computed(() => ({ [IsFocusedClass]: state.value })),
    events: {
      focus() {
        state.value = true
      },
      blur() {
        state.value = false
      },
    },
  }
}
